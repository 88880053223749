<template>
  <div class="form__question" :class="{ loading: clipboardLoader }">
    <div class="form__content">
      <div class="payment__section">

        <template v-if="!alreadyPaid">
          <!-- <div class="form__stripe">
            <div>
              <div id="stripe-payment-element">
                <div id="stripe-element-error-message"></div>
              </div>
            </div>
          </div>
          <div class="form__item coupon-input-container">
            <div v-if="!loading && discountApplied" class="form__item">
              <p class="discount-message">Applied discount of ${{ discountApplied }} to your purchase!</p>
            </div>
          </div> -->
        </template>
      </div>

      <div v-if:="errorMessages" class="warning">
        {{this.errorMessages}}
      </div>
    </div>
    <Loader :visible="loading" fixed blocking />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { getFunctions, httpsCallable } from 'firebase/functions'
// See https://stackoverflow.com/questions/62358488/importing-stripe-js-as-an-es-module-into-vue
// import { loadStripe } from '@stripe/stripe-js/pure'
import Loader from '@/components/Loader'

const cloudFunction = getFunctions()
export default {
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Loader
  },

  async created () {
    // const result = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY, {
    //   betas: ['process_order_beta_1'],
    //   apiVersion: '2022-08-01; orders_beta=v4'
    // })
    // this.stripeJS_elements = result.elements
    // this.stripeJS_processOrder = result.processOrder
    // //
    // this.stripeJS_confirmPayment = result.confirmPayment
  },

  data () {
    return {
      loading: false,
      checkPhone: {
        isValid: false
      },
      stripeResponses: {},
      cloudFunction: null,
      httpsCall: null,
      errorMessages: '',
      associatedStripeCustomer: null,
      stripeJS_elements: {},
      stripeJS_processOrder: {},
      stripeJS_confirmPayment: {},
      stripeClientElements: null,
      alreadyPaid: false,
      paymentIntent: null,
      orderData: null,
      couponCode: null,
      discountApplied: null,
      successURL: '',
      cancelURL: ''
    }
  },

  computed: {
    ...mapState([
      'showDebug',
      'firebaseAuth',
      'firestore',
      'stripeClient',
      'usStates',
      'selectionServiceFee',
      'selectionDiscountThreshold',
      'clipboardLoader'
    ]),
    ...mapGetters([
      'currentUser',
      'userProfile'
    ])
  },

  async mounted () {
    this.successURL = window.location.href
    this.cancelURL = window.location.href
    const mountedStartTime = new Date().getTime()
    this.setClipboardLoader(true)
    if (!this.userProfile?.stripeCustomerId) {
      await httpsCallable(cloudFunction, 'sendStripeCreateNewCustomer')()
    } else {

    }

    const existingOrderId = this.userProfile?.selectionFeeOrderId
    if (existingOrderId) {
      // If the user has already created an order, we'll retrieve it rather than creating another one.
      if (this.userProfile.selectionFeeOrderStatus === 'open') {
        // window.location.assign(this.userProfile.selectionFeeCheckoutUrl)
        await this.createSelectionFeePaymentOrder()
      }
      if (this.userProfile.selectionFeeOrderStatus === 'complete') {
        this.alreadyPaid = true
      }
    } else {
      // If the user doesn't have a Stripe customer ID, that needs to be set up first.
      // This should happen when the user profile is created, but this is here as a backup.
      // Once the user is created, we can set up payment intent.
      await this.createSelectionFeePaymentOrder()
    }
    // We want to show the clipboard loader for a minimum of 2500ms
    const mountedEndTime = new Date().getTime()
    const timeDiff = mountedEndTime - mountedStartTime
    let delay = 0
    const minTimeShown = 2500
    if (timeDiff < minTimeShown) {
      delay = minTimeShown - timeDiff
    }
    setTimeout(() => {
      this.setClipboardLoader(false)
    }, delay)
  },
  methods: {
    ...mapMutations(['setGlobalError', 'setClipboardLoader']),
    ...mapActions(['updateFirestoreUserProfile', 'updateFirestoreCurrentQuiz']),

    async updatedCouponCode () {
      this.errorMessages = ''
      if (!this.couponCode) return
      this.loading = true

      try {
        const response = await httpsCallable(cloudFunction, 'applyCouponCode')({
          code: this.couponCode,
          order: this.orderData.id
        })

        if (response.data === 'invalid-code') {
          this.errorMessages = 'Sorry this Code is not Valid.'
        } else {
          this.discountApplied = response.data?.total_details?.amount_discount / 100
        }
      } catch (err) {
        this.setGlobalError('Sorry, there was an error applying your coupon.')
      }
      this.loading = false
    },

    // TODO: Currently inactive
    async atStripeUpdateCustomer () {
      this.errorMessages = null

      const stripePayload = {
        name: this.userProfile.shipping_name || this.userProfile.first_name,
        email: this.userProfile.email_address,
        // TODO
        shipping: {
          address: {
            postal_code: '',
            state: ''
          }
        }
      }
      const stripeCloudFunction = httpsCallable(cloudFunction, 'sendStripeUpdateCustomer')
      await stripeCloudFunction(stripePayload).then((result) => {
        return result
      })

      return stripePayload
    },

    async createSelectionFeePaymentOrder () {
      try {
        const result = await httpsCallable(cloudFunction, 'createSelectionFeeOrder')({
          surveyId: this.$route.params.selectionId,
          paymentType: 'selection-fee',
          successUrl: this.successURL,
          cancelUrl: this.cancelURL
        })

        this.orderData = result.data
        window.location.assign(this.orderData.url)
      } catch (err) {
        this.setGlobalError('Sorry, an error occurred when creating your order.')
      }
    },

    /**
     * This initiates the single purchase of the Interior Design Services
     * Note that this is being controlled by the grandparent Quiz.vue
     * under the checkSpecialCases() subroutine.
     */
    async atStripeSubmitConfirmPayment (e) { // REMOTE STEP 2 (SUBMIT)
      const elements = this.stripeClientElements
      let confirmPaymentResponse = {}

      // If the user doesn't already have an address associated with their Stripe customer,
      // We'll save it here. Note that we only have zip / state (inferred) at this point
      // if (!this.associatedStripeCustomer.shipping) {
      //   confirmPaymentResponse = await this.atStripeUpdateCustomer() // REMOTE STEP 3
      // }

      //
      // if (!confirmPaymentResponse) {
      //   return false
      // }

      // confirmPaymentResponse = await this.stripeJS_processOrder({
      //   elements,
      //   redirect: 'if_required',
      //   confirmParams: {
      //     return_url: `${process.env.VUE_APP_ROOT_URL}/stripeCheckout`
      //   }
      // })
      confirmPaymentResponse = await this.stripeJS_confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${process.env.VUE_APP_ROOT_URL}/stripeCheckout`
        }
      })

      await this.uponStripeConfirmPayment(confirmPaymentResponse)

      return confirmPaymentResponse
    },

    async uponStripeConfirmPayment (payload) {
      if (payload.error) {
        const error = payload.error ? payload.error : null

        switch (error.type) { // Handles card level errors returned from stripe
          case 'card_error': {
            this.errorMessages = error.message

            return payload
            // break
          }

          case 'validation_error': {
            this.errorMessages = 'Validation Error, ' + error.message

            return payload
            // break
          }

          default:

            this.errorMessages = error.message + ' No charges were made.'

            return payload
        }
      } else if (payload.paymentIntent) { // Charge was successful!
        this.errorMessages = null
        this.alreadyPaid = true

        return payload
      } else { // should not get here
        return payload
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.blurred.modal-backdrop .modal {
    max-height: 650px;
}
.form__question {
  h3, p {
    color: white !important;
  }

  &.loading {
    height: 0;
    overflow: hidden;
    opacity: 0;

    @include breakpoint($s) {
      height: calc(100vh - 380px);
    }
  }
}

#stripe-payment-element {
  margin-top: 2rem;
}

.coupon-input-container {
  margin: calc(var(--gutter) * 2) 0 0;

  .discount-message {
    @include meta-text;
  }
}
.coupon-input {
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 66%;

  input {
    background-color: white;
  }

  &.valid {
    background-image: url('../../assets/images/check.svg');
  }
  &.invalid {
    background-image: url('../../assets/images/close.svg');
  }
}

.warning {
  grid-column: span 12;
}
.payment__section {
  display: grid;
  grid-column: span 12;
}
.payment__copy {
  font-size: 1.25rem;
  line-height: 1.5rem;
  grid-column: span 12;

  &--list {
    list-style: disc;
    padding-left: var(--gutter);
    margin: var(--leading-h2) 0;
  }

  &--center {
    text-align: center;
  }

  strong {
    font-weight: 700;
  }
}
.payment__heading {
  font-family: 'GT America Expanded';
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 300;
  grid-column: span 12;
  margin-bottom: var(--leading-h2);

  &--section {
    margin-top: var(--leading-h2);
  }
}

.form__stripe {
  width: 100%;
  grid-column: span 12;
}
</style>
