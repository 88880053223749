<template lang="">
  <div class="item" @mouseover="handleMouseOver" @mouseleave="handleMouseOut">
    <div class="wrapper">
      <img :src="src" alt="" />
      <div class="info">
        <p>{{name}}</p>
        <div class="info-button" @click="handleInfo">
          <img src="@/assets/images/info.svg" />
        </div>
      </div>
    </div>
    <div class="close-button" @click="handleClose">
      <img src="@/assets/images/close-remove-button.svg" />
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import { gsap } from 'gsap'

export default {
  name: 'room-builder-stage-item',

  props: {
    id: {
      type: String,
      default: ''
    },

    name: {
      type: String,
      default: ''
    },

    itemPrice: {
      type: Number,
      default: 0
    },

    size: {
      type: String,
      default: null
    },

    globalScale: {
      type: Number,
      default: 1
    },

    src: {
      type: String,
      default: ''
    },

    x: {
      type: Number,
      default: 0
    },

    y: {
      type: Number,
      default: 0
    },

    offsetX: {
      type: Number,
      default: 0
    },

    offsetY: {
      type: Number,
      default: 0
    },

    percentX: {
      type: Number,
      default: 0
    },

    stageWidth: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      pos1: 0,
      pos2: 0,
      pos3: 0,
      pos4: 0,
      height: 0
    }
  },

  computed: {
    scaleFactor () {
      let scaleFactor = 1
      if (!this.size) return scaleFactor

      // Split size string into wdh
      const wdhArray = this.size.split(' x ')
      // Get width
      const wString = wdhArray?.length && wdhArray[0]

      const isFeet = wString.indexOf('\'') > 0

      const splitString = isFeet ? '\'' : '"'
      // Extract number from width string
      let w = wString ? wString.split(splitString) : []
      w = w?.length ? w[0] : null
      w = w > 0 ? parseFloat(w) : null
      if (isFeet) w *= 12

      // Convert to scale value
      if (!w) return scaleFactor
      if (w <= 24) {
        scaleFactor = 0.33
      }
      if (w > 24 && w <= 48) {
        scaleFactor = 0.5
      }
      if (w > 48 && w <= 72) {
        scaleFactor = 0.75
      }

      return scaleFactor
    }
  },

  async mounted () {
    await nextTick()
    // Wait for images to load before initializing, so height is available
    const furnitureImage = new Image()
    furnitureImage.onload = () => {
      this.initialize()
    }

    furnitureImage.src = this.$el.firstChild.firstChild.src
    window.addEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize () {
      this.setPosition()
    },

    setPosition () {
      gsap.set(this.$el, {
        left: this.percentX * this.stageWidth,
        top: this.y - this.offsetY,
        height: this.height - this.$el.querySelector('.info').clientHeight,
        transformOrigin: '0% 0%'
      })
      gsap.to(this.$el, {
        scale: (this.stageWidth / 660) * this.globalScale,
        duration: 0.5,
        ease: 'Cubic.easeInOut'
      })
      gsap.to(this.$el.querySelector('.wrapper').firstChild, {
        scale: this.scaleFactor,
        duration: 0.5,
        ease: 'Cubic.easeInOut'
      })
    },

    initialize () {
      this.height = this.$el.clientHeight
      this.$el.onmousedown = this.handleDragMouseDown

      this.setPosition()

      gsap.to(this.$el, {
        opacity: 1,
        duration: 0.5,
        ease: 'Cubic.easeInOut'
      })
    },
    deselect () {
      gsap.to(this.$el, {
        background: 'rgba(255, 255, 255, 0.0)',
        boxShadow: '0 0 0px 0px rgba(0, 0, 0, 0)',
        zIndex: 0,
        height: this.height - this.$el.querySelector('.info').clientHeight,
        duration: 0.5,
        ease: 'Cubic.easeInOut'
      })

      gsap.to(this.$el.querySelector('.close-button'), {
        opacity: 0,
        scale: 2,
        duration: 0.5,
        ease: 'Cubic.easeInOut',
        onComplete: () => {
          gsap.set(this.$el.querySelector('.close-button'), { display: 'none' })
        }
      })
    },

    handleMouseOver () {
      this.$emit('ITEM_MOUSEOVER', {
        id: this.id,
        item: this.$el
      })
    },

    handleMouseOut () {
      this.$emit('ITEM_MOUSEOUT', {
        id: this.id,
        item: this.$el
      })
    },

    handleClose () {
      this.$emit('ITEM_CLOSE', {
        id: this.id,
        item: this.$el
      })
    },

    handleInfo () {
      this.$emit('ITEM_INFO', {
        id: this.id,
        item: this.$el
      })
    },

    handleDragMouseDown (e) {
      e = e || window.event
      e.preventDefault()

      this.pos3 = e.clientX
      this.pos4 = e.clientY

      gsap.to(this.$el, {
        background: 'rgba(255, 255, 255, 0.66)',
        boxShadow: '0 0 20px 0px rgba(0, 0, 0, 0.2)',
        zIndex: 1,
        height: this.height,
        duration: 0.5,
        ease: 'Cubic.easeInOut'
      })

      gsap.to(this.$el.querySelector('.close-button'), {
        display: 'block',
        opacity: 1,
        scale: 1,
        duration: 0.5,
        ease: 'Cubic.easeInOut'
      })

      this.$emit('ITEM_CLICK', {
        id: this.id,
        item: this
      })

      document.onmouseup = this.handleDragRelease
      document.onmousemove = this.handleDrag
    },

    handleDrag (e) {
      e = e || window.event
      e.preventDefault()

      this.pos1 = this.pos3 - e.clientX
      this.pos2 = this.pos4 - e.clientY
      this.pos3 = e.clientX
      this.pos4 = e.clientY

      this.$el.style.left = (this.$el.offsetLeft - this.pos1) + 'px'
      this.$el.style.top = (this.$el.offsetTop - this.pos2) + 'px'
    },

    handleDragRelease (e) {
      document.onmouseup = null
      document.onmousemove = null

      this.$emit('ITEM_DRAG_RELEASE', {
        id: this.id,
        item: this.$el
      })
    }
  },

  watch: {
    globalScale () {
      gsap.to(this.$el, {
        scale: (this.stageWidth / 660) * this.globalScale,
        duration: 0.5
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  background-color: rgba(255, 255, 255, 0.0);
  text-align: center;
  width: 200px;
  top: 0;
  color: black;
  position: absolute;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  mix-blend-mode: multiply;

  .wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    transform-origin: center center;

    img {
      width: 100%;
    }

    .info {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      text-align: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #73716F;
      // margin-top: -10px;
      padding-bottom: 20px;

      p {
        margin-bottom: 8px;
      }

      .info-button {
        width: 17px;
        height: 17px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .close-button {
    position: absolute;
    top: -14px;
    left: -14px;
    width: 28px;
    height: 28px;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}
</style>
