<template>
  <div class="product__images" @click="nextSlide">
    <div
      class="product__images__item"
      v-for="(image, index) in images"
      :key="index"
    >
      <img class="product__images__item__image" :src="image.url">
    </div>
  </div>
</template>

<script>
import Flickity from 'flickity'
import 'flickity/dist/flickity.min.css'

export default {
  name: 'ProductImages',
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      productImages: null
    }
  },
  methods: {
    slideshow () {
      const flickityOptions = {
        imagesLoaded: true,
        wrapAround: true,
        prevNextButtons: false
      }
      if (this.images.length <= 1) {
        flickityOptions.pageDots = false
      }

      // eslint-disable-next-line no-unused-vars
      this.productImages = new Flickity('.product__images', flickityOptions)
    },

    nextSlide () {
      // Disabled for now, as it causes issues with draggable
      // this.productImages.next()
    }
  },
  mounted () {
    this.slideshow()
  }
}
</script>

<style lang="scss" scoped>
.product__images {
  grid-column: span 12;
  margin-top: 3rem;
  margin-bottom: 3rem;
  height: 400px;
  width: 100%;

  &__item {
    width: 100%;
    padding: 0 4rem;
    text-align: center;
    line-height: 0;
    height: 100%;

    &__image {
      position: relative;
      top: 50%;
      max-height: 100%;
      max-width: 100%;
      transform: translateY(-50%);
    }
  }
}
</style>
