<template lang="">
  <div class='room-builder-thumbnails'>
    <div class="thumbnails" v-for="(product, index) in products" :key="index">
      <RoomBuilderThumbnail
        class="thumbnail"
        :id="product.recordId"
        :src="product.cloudinaryImages && product.cloudinaryImages[0] ? product.cloudinaryImages[0].url : ''"
        :name="product.name"
        :isMobile="isMobile"
      />
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import RoomBuilderThumbnail from '@/components/room-builder/room-builder-thumbnail.vue'

export default {
  name: 'room-builder-thumbnails',

  components: {
    RoomBuilderThumbnail
  },

  props: {
    products: {
      type: Array,
      default: () => []
    },

    isMobile: {
      type: Boolean,
      default: false
    }
  },

  mounted () {
    gsap.delayedCall(1, () => {

    })
  },

  methods: {
    // handleClick (e) {
    //   e = e || window.event
    //   e.preventDefault()

    //   this.$emit('THUMBNAIL_CLICKED', e.target)
    //   this.select(e.target)
    // },

    select (e) {
      gsap.to(e, {
        border: '5px solid #ff0000',
        duration: 0.3,
        ease: 'Cubic.easeInOut'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .room-builder-thumbnails {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-height: 710px;
    overflow-y: scroll;
    align-items: center;
  }

  .thumbnails:last-child {
    margin-bottom: 54px; // Add extra margin to account for feedback button
  }

  .room-builder-thumbnails::-webkit-scrollbar { width: 0 !important }
  .room-builder-thumbnails { overflow: -moz-scrollbars-none; }
  .room-builder-thumbnails { -ms-overflow-style: none; }

  @include breakpoint($m-down) {
    .room-builder-thumbnails {
      // max-height: 430px;
      max-height: calc(100vh - 380px);
      min-height: 400px;
    }
  }
</style>
